const map = {
  type: "FeatureCollection",
  name: "GBR_adm0",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { FID: 0 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.3036110401153, 50.767639160156421],
              [-1.21416604518879, 50.735137939453409],
              [-1.106943964958191, 50.720695495605469],
              [-1.069720983505249, 50.682918548584212],
              [-1.165832042694092, 50.644027709960938],
              [-1.182500004768372, 50.59736251831049],
              [-1.299165964126587, 50.5745849609375],
              [-1.390277028083744, 50.627361297607422],
              [-1.484722018241882, 50.666248321533203],
              [-1.523054957389832, 50.707637786865234],
              [-1.354166030883732, 50.739028930664233],
              [-1.3036110401153, 50.767639160156421],
            ],
          ],
          [
            [
              [0.745833992958183, 51.448749542236442],
              [0.885277986526546, 51.421249389648381],
              [0.950276970863456, 51.374584197998331],
              [0.894722998142186, 51.354026794433651],
              [0.768055021762905, 51.38347244262718],
              [0.727500021457729, 51.405693054199446],
              [0.745833992958183, 51.448749542236442],
            ],
          ],
          [
            [
              [-4.426389217376709, 53.430137634277571],
              [-4.283055782318115, 53.411529541015909],
              [-4.20527791976923, 53.29486083984375],
              [-4.119721889495793, 53.319305419921875],
              [-4.045279026031437, 53.304584503173885],
              [-4.087500095367432, 53.262359619140739],
              [-4.210277080535832, 53.20958328247093],
              [-4.215833187103271, 53.189582824707202],
              [-4.356944084167424, 53.130695343017692],
              [-4.443056106567269, 53.15708160400402],
              [-4.554721832275391, 53.249305725097656],
              [-4.581387996673527, 53.333473205566463],
              [-4.575277805328369, 53.404026031494084],
              [-4.426389217376709, 53.430137634277571],
            ],
          ],
          [
            [
              [-6.482500076293945, 55.25263977050804],
              [-6.333611011505127, 55.238750457763842],
              [-6.236946105956974, 55.204303741455135],
              [-6.156942844390869, 55.227359771728743],
              [-6.065276145935002, 55.19708251953125],
              [-6.024168014526367, 55.139305114746094],
              [-6.05583381652832, 55.058750152588118],
              [-5.964167118072453, 55.051250457763842],
              [-5.993054866790771, 54.98930740356468],
              [-5.919166088104191, 54.962638854980469],
              [-5.879723072051945, 54.909584045410099],
              [-5.795279026031437, 54.848751068115462],
              [-5.721944808959961, 54.846248626709212],
              [-5.687500953674316, 54.76625061035179],
              [-5.764165878295842, 54.71958160400402],
              [-5.8313889503479, 54.708194732666072],
              [-5.897500038146973, 54.671806335449219],
              [-5.896944046020508, 54.624305725097656],
              [-5.750277042388916, 54.674026489257756],
              [-5.574721813201791, 54.674861907959269],
              [-5.525279045104924, 54.640415191650561],
              [-5.520277976989746, 54.59597396850603],
              [-5.461390018463078, 54.495971679687727],
              [-5.462500095367432, 54.38236236572277],
              [-5.528055191040039, 54.359306335449332],
              [-5.579165935516357, 54.398471832275504],
              [-5.533610820770207, 54.451805114746378],
              [-5.556944847106877, 54.531528472900391],
              [-5.684721946716309, 54.582359313964844],
              [-5.706943988800049, 54.537361145019759],
              [-5.658055782318115, 54.514862060547102],
              [-5.626388072967472, 54.463195800781307],
              [-5.640276908874512, 54.400138854980412],
              [-5.556944847106877, 54.376525878906421],
              [-5.538611888885498, 54.337081909179915],
              [-5.558610916137695, 54.287361145019645],
              [-5.63638877868641, 54.25041580200218],
              [-5.830832958221436, 54.240417480468807],
              [-5.891389846801701, 54.206806182861612],
              [-5.871943950653076, 54.175140380859375],
              [-5.90250110626215, 54.099582672119254],
              [-6.064258098602295, 54.040088653564567],
              [-6.120233058929443, 54.105838775634993],
              [-6.358060836791992, 54.108215332031477],
              [-6.389462947845402, 54.065113067626896],
              [-6.472287178039551, 54.066909790039176],
              [-6.592153072357178, 54.044822692871094],
              [-6.661441802978459, 54.071823120117244],
              [-6.626046180725098, 54.149818420410213],
              [-6.662841796874943, 54.198406219482706],
              [-6.77783203125, 54.207813262939453],
              [-6.810042858123779, 54.225318908691634],
              [-6.869012832641488, 54.34420394897478],
              [-7.003812789916992, 54.419204711914006],
              [-7.041902065276986, 54.415897369384766],
              [-7.20209789276123, 54.297706604003849],
              [-7.149693965911865, 54.272510528564453],
              [-7.142300128936654, 54.227310180664347],
              [-7.240301132202148, 54.205215454101506],
              [-7.274088859558105, 54.124519348144474],
              [-7.333889961242619, 54.119525909423828],
              [-7.428689956664982, 54.154422760009936],
              [-7.473382949829102, 54.125923156738281],
              [-7.554275035858154, 54.128017425537223],
              [-7.690956115722656, 54.206523895263899],
              [-7.828145980834961, 54.205524444580078],
              [-7.877947807312012, 54.298721313476562],
              [-7.942931175231934, 54.3073158264163],
              [-8.003613471984863, 54.36278152465826],
              [-8.042832374572697, 54.365409851074332],
              [-8.147810935974064, 54.437412261963004],
              [-8.11441612243641, 54.469009399414062],
              [-8.03971958160389, 54.490402221679858],
              [-7.996231079101562, 54.543502807617244],
              [-7.829245090484562, 54.544700622558707],
              [-7.739074230194035, 54.600135803222884],
              [-7.75603008270258, 54.619716644287223],
              [-7.899225234985352, 54.667396545410327],
              [-7.834397792816105, 54.738483428955305],
              [-7.750647068023682, 54.705471038818359],
              [-7.639691829681396, 54.747386932373047],
              [-7.544353961944523, 54.756790161133097],
              [-7.530230045318604, 54.808616638183651],
              [-7.445333957672119, 54.87998199462902],
              [-7.4429931640625, 54.934509277343807],
              [-7.347958087921143, 55.048969268798999],
              [-7.263638973236084, 55.066703796386832],
              [-7.133056163787842, 55.042083740234432],
              [-7.053612232208252, 55.048751831054744],
              [-6.98638916015625, 55.119304656982365],
              [-6.952500820159912, 55.192916870117415],
              [-6.863056182861328, 55.166248321533203],
              [-6.730278015136662, 55.170417785644759],
              [-6.709167003631592, 55.193748474121207],
              [-6.605278968810978, 55.20597076416044],
              [-6.482500076293945, 55.25263977050804],
            ],
          ],
          [
            [
              [-5.260276794433537, 55.721527099609659],
              [-5.160276889801025, 55.679027557373274],
              [-5.131945133209229, 55.600692749023438],
              [-5.081943035125676, 55.552360534667912],
              [-5.079166889190674, 55.465415954589844],
              [-5.105277061462346, 55.44041824340843],
              [-5.250277996063176, 55.438751220703125],
              [-5.31583309173584, 55.464862823486442],
              [-5.352499961852971, 55.51069259643549],
              [-5.348054885864258, 55.569305419921989],
              [-5.397500991821289, 55.614028930664062],
              [-5.364167213439885, 55.679584503174055],
              [-5.260276794433537, 55.721527099609659],
            ],
          ],
          [
            [
              [-5.185832977294922, 55.927082061767578],
              [-5.079723834991455, 55.882362365722656],
              [-5.023055076599121, 55.842918395996094],
              [-5.001389980316105, 55.772914886474723],
              [-5.120831966400146, 55.77180480957054],
              [-5.139721870422363, 55.835693359375227],
              [-5.220277786254826, 55.903751373291129],
              [-5.185832977294922, 55.927082061767578],
            ],
          ],
          [
            [
              [-6.124166965484619, 55.938194274902628],
              [-6.131390094757023, 55.890415191650675],
              [-6.08472204208374, 55.783195495605639],
              [-6.046389102935791, 55.762359619140568],
              [-6.019721031188965, 55.682640075683537],
              [-6.144722938537598, 55.625972747802962],
              [-6.191944122314396, 55.632915496826286],
              [-6.26694393157959, 55.578750610351562],
              [-6.336389064788818, 55.588748931884936],
              [-6.328609943389836, 55.625694274902571],
              [-6.260276794433594, 55.657638549804801],
              [-6.332500934600773, 55.741527557373217],
              [-6.486944198608342, 55.670696258545206],
              [-6.525833129882699, 55.692359924316349],
              [-6.455833911895695, 55.772914886474723],
              [-6.45527791976923, 55.853195190429858],
              [-6.269167900085392, 55.882362365722656],
              [-6.198057174682617, 55.924583435058821],
              [-6.124166965484619, 55.938194274902628],
            ],
          ],
          [
            [
              [-6.146389007568359, 56.132637023925781],
              [-6.195278167724553, 56.06180572509777],
              [-6.245833873748779, 56.085693359375],
              [-6.146389007568359, 56.132637023925781],
            ],
          ],
          [
            [
              [-5.708610057830754, 56.149307250976619],
              [-5.687500953674316, 56.111526489257869],
              [-5.791944026947021, 56.013748168945256],
              [-5.851388931274414, 55.929306030273608],
              [-5.935832977294922, 55.867084503174055],
              [-5.963611125945988, 55.792083740234489],
              [-6.064723014831543, 55.805694580078125],
              [-6.09305477142334, 55.891250610351676],
              [-5.948056221008301, 56.038471221923828],
              [-5.708610057830754, 56.149307250976619],
            ],
          ],
          [
            [
              [-6.754166126251221, 56.556526184082088],
              [-6.823054790496769, 56.490970611572493],
              [-6.878611087799072, 56.488193511963004],
              [-6.889165878295898, 56.446250915527571],
              [-6.976387977600098, 56.471248626708984],
              [-6.977500915527344, 56.51652908325218],
              [-6.873054981231633, 56.518211364746207],
              [-6.754166126251221, 56.556526184082088],
            ],
          ],
          [
            [
              [-6.126389026641846, 56.656250000000227],
              [-6.059165954589787, 56.634582519531364],
              [-5.988055229187012, 56.578750610351733],
              [-5.956944942474365, 56.523193359375114],
              [-5.798055171966553, 56.515693664550838],
              [-5.719167232513428, 56.485973358154297],
              [-5.646389961242676, 56.424583435058707],
              [-5.695277214050293, 56.36986160278343],
              [-5.833055019378662, 56.310417175292969],
              [-5.985833168029785, 56.322639465332259],
              [-6.040832996368408, 56.293193817138899],
              [-6.246943950653019, 56.288192749023722],
              [-6.264167785644531, 56.263748168945369],
              [-6.381944179534855, 56.285972595215014],
              [-6.349721908569279, 56.347084045410213],
              [-6.190278053283691, 56.332637786865234],
              [-6.200279235839844, 56.391250610351562],
              [-6.120832920074406, 56.451248168945426],
              [-6.239721775054932, 56.465415954589787],
              [-6.333054065704346, 56.532638549804915],
              [-6.315277099609375, 56.602359771728743],
              [-6.126389026641846, 56.656250000000227],
            ],
          ],
          [
            [
              [-6.467501163482609, 56.691528320312727],
              [-6.466945171356144, 56.652915954589787],
              [-6.565278053283691, 56.58680725097679],
              [-6.678055763244572, 56.581249237060661],
              [-6.534167766571045, 56.675415039062443],
              [-6.467501163482609, 56.691528320312727],
            ],
          ],
          [
            [
              [-6.136943817138672, 56.944583892822379],
              [-6.115831851959229, 56.886806488037223],
              [-6.210277080535889, 56.888473510742131],
              [-6.136943817138672, 56.944583892822379],
            ],
          ],
          [
            [
              [-7.447498798370361, 57.059028625488452],
              [-7.444167137145939, 57.019306182861555],
              [-7.38361120223999, 57.004859924316577],
              [-7.435832023620605, 56.947639465332202],
              [-7.529168128967285, 56.946529388427848],
              [-7.506944179534912, 57.005138397216967],
              [-7.447498798370361, 57.059028625488452],
            ],
          ],
          [
            [
              [-6.329165935516301, 57.060695648193587],
              [-6.254167079925423, 57.033195495605582],
              [-6.259723186492863, 56.960971832275447],
              [-6.329721927642709, 56.935138702392749],
              [-6.453054904937744, 57.002082824707088],
              [-6.329165935516301, 57.060695648193587],
            ],
          ],
          [
            [
              [-7.365832805633545, 57.412639617919979],
              [-7.274167060852051, 57.381805419922102],
              [-7.227499008178597, 57.347915649414347],
              [-7.203610897064152, 57.279582977294922],
              [-7.251945018768254, 57.259582519531364],
              [-7.264721870422363, 57.194862365722884],
              [-7.245831966400146, 57.135971069335994],
              [-7.351387977600098, 57.101249694824276],
              [-7.412500858306828, 57.148471832275447],
              [-7.440833091735783, 57.238750457763615],
              [-7.397500038146916, 57.302082061767521],
              [-7.411388874053955, 57.387916564941634],
              [-7.365832805633545, 57.412639617919979],
            ],
          ],
          [
            [
              [-7.317500114440918, 57.495140075683707],
              [-7.203054904937744, 57.460693359375],
              [-7.205276966094971, 57.414859771728516],
              [-7.301389217376652, 57.397361755371094],
              [-7.393610954284668, 57.425971984863281],
              [-7.411944866180363, 57.470695495605696],
              [-7.317500114440918, 57.495140075683707],
            ],
          ],
          [
            [
              [-6.003612041473389, 57.516529083251953],
              [-5.978055953979435, 57.492637634277401],
              [-6.025278091430607, 57.442081451415959],
              [-5.993610858917236, 57.355693817138672],
              [-6.068611145019474, 57.333473205566406],
              [-6.085278034210205, 57.421527862548828],
              [-6.003612041473389, 57.516529083251953],
            ],
          ],
          [
            [
              [-7.191944122314396, 57.697639465332259],
              [-7.163609981536865, 57.654582977294922],
              [-7.063055038452092, 57.640140533447493],
              [-7.103055953979492, 57.607639312744084],
              [-7.163055896758976, 57.508472442626953],
              [-7.322500228881836, 57.509029388427678],
              [-7.354166030883789, 57.552639007568416],
              [-7.421390056610051, 57.581249237060604],
              [-7.482500076293888, 57.567081451416243],
              [-7.519722938537598, 57.602638244628906],
              [-7.483612060546875, 57.662918090820312],
              [-7.375833988189697, 57.63902664184576],
              [-7.290833950042725, 57.653472900390909],
              [-7.191944122314396, 57.697639465332259],
            ],
          ],
          [
            [
              [-6.350833892822152, 57.708194732666186],
              [-6.260276794433594, 57.680973052978743],
              [-6.139165878295785, 57.581806182861385],
              [-6.137499809265137, 57.366249084472599],
              [-6.100832939147892, 57.325973510742301],
              [-5.999166011810303, 57.272914886474609],
              [-5.888054847717285, 57.240139007568359],
              [-5.738056182861328, 57.274860382080078],
              [-5.647500038146916, 57.253749847412166],
              [-5.668611049651986, 57.209304809570312],
              [-5.786943912506104, 57.167362213134879],
              [-5.922500133514347, 57.042640686035156],
              [-6.024168014526367, 57.024307250976733],
              [-6.037500858306828, 57.052360534667969],
              [-5.987501144409123, 57.104862213134709],
              [-5.983057022094727, 57.166805267334098],
              [-6.083055973052979, 57.126251220703182],
              [-6.131943225860539, 57.184028625488338],
              [-6.313055038452148, 57.159584045410384],
              [-6.450276851654053, 57.261528015136832],
              [-6.446389198303166, 57.320972442626953],
              [-6.471388816833496, 57.377082824707145],
              [-6.555276870727539, 57.384860992431584],
              [-6.563611984252873, 57.335971832275504],
              [-6.722498893737793, 57.373748779296875],
              [-6.78805685043335, 57.43125152587902],
              [-6.748054981231689, 57.500137329101676],
              [-6.468057155609074, 57.508193969726562],
              [-6.406943798065186, 57.523471832275504],
              [-6.39527702331543, 57.612915039062671],
              [-6.426942825317383, 57.640693664551065],
              [-6.354166030883789, 57.671249389648381],
              [-6.350833892822152, 57.708194732666186],
            ],
          ],
          [
            [
              [-6.887499809265137, 58.271804809570369],
              [-6.786943912506104, 58.224304199218807],
              [-6.867499828338566, 58.207084655761719],
              [-6.887499809265137, 58.271804809570369],
            ],
          ],
          [
            [
              [-6.260276794433594, 58.519584655761832],
              [-6.181944847106934, 58.469581604003963],
              [-6.168056011199951, 58.426807403564737],
              [-6.205832958221379, 58.384582519531193],
              [-6.191390037536621, 58.349582672119084],
              [-6.245277881622314, 58.293750762939624],
              [-6.321390151977482, 58.268470764160327],
              [-6.343610763549748, 58.237361907959041],
              [-6.291389942169189, 58.206806182861555],
              [-6.201388835906982, 58.248470306396769],
              [-6.166388034820557, 58.209304809570256],
              [-6.255833148956242, 58.179584503173885],
              [-6.38861083984375, 58.199584960937443],
              [-6.35694408416748, 58.039585113525447],
              [-6.385831832885742, 58.000415802001953],
              [-6.535833835601807, 57.917915344238281],
              [-6.673056125640869, 57.922637939453068],
              [-6.668612003326416, 57.879306793212891],
              [-6.792500972747746, 57.892639160156364],
              [-6.734722137451172, 57.827362060547102],
              [-6.848054885864258, 57.802639007568587],
              [-6.964168071746826, 57.72986221313505],
              [-7.086945056915283, 57.807918548583984],
              [-6.924166202545166, 57.909862518310774],
              [-6.943612098693848, 57.9495849609375],
              [-7.080277919769287, 57.966804504394588],
              [-7.098611831665039, 58.068473815918026],
              [-7.131388187408447, 58.138748168945312],
              [-7.090833187103271, 58.163749694824219],
              [-7.050278186798096, 58.23125076293968],
              [-6.999166011810246, 58.234306335449219],
              [-6.944722175598145, 58.194305419921875],
              [-6.878055095672607, 58.179306030273551],
              [-6.835278034210148, 58.203472137451286],
              [-6.756944179534798, 58.194305419921875],
              [-6.828610897064209, 58.284305572509879],
              [-6.796945095062256, 58.307083129882869],
              [-6.653610229492131, 58.351806640625],
              [-6.550833225250244, 58.36597061157255],
              [-6.523611068725586, 58.395137786865348],
              [-6.260276794433594, 58.519584655761832],
            ],
          ],
          [
            [
              [-4.885276794433594, 55.729862213134766],
              [-4.857501029968262, 55.749862670898665],
              [-4.889722824096623, 55.818748474121151],
              [-4.899722099304199, 55.890972137451286],
              [-4.880832195281982, 55.9415283203125],
              [-4.793610095977783, 55.963748931884766],
              [-4.671389102935791, 55.933193206787109],
              [-4.703610897064209, 55.992362976074503],
              [-4.836945056915283, 55.982917785644531],
              [-4.894167900085336, 55.998470306396484],
              [-4.979166030883789, 55.860973358154524],
              [-5.045833110809326, 55.870971679687727],
              [-5.18972110748291, 55.945972442626953],
              [-5.239720821380558, 55.902084350586165],
              [-5.200277805328312, 55.830417633056641],
              [-5.317501068115178, 55.856803894042969],
              [-5.352499961852971, 55.897083282470817],
              [-5.341389179229736, 55.996807098388729],
              [-5.450276851654053, 56.005973815918139],
              [-5.418610095977726, 55.896251678467024],
              [-5.314167022705021, 55.785415649414347],
              [-5.447501182556152, 55.709861755371207],
              [-5.47527885437006, 55.604583740234489],
              [-5.548610210418701, 55.417915344238281],
              [-5.515277862548828, 55.370971679687443],
              [-5.601943969726562, 55.307083129882756],
              [-5.71805477142334, 55.293193817138729],
              [-5.798055171966553, 55.298194885254134],
              [-5.798055171966553, 55.391803741455135],
              [-5.720832824706974, 55.430137634277344],
              [-5.705277919769287, 55.552082061767521],
              [-5.663610935211182, 55.666526794433821],
              [-5.571387767791748, 55.766529083251953],
              [-5.661388874053955, 55.796806335449446],
              [-5.708055973052979, 55.965694427490462],
              [-5.50416707992548, 56.184581756591797],
              [-5.590277194976807, 56.168472290039062],
              [-5.551943778991699, 56.238193511963061],
              [-5.621943950653076, 56.264583587646712],
              [-5.640832901000977, 56.311805725097713],
              [-5.539721012115479, 56.349861145019531],
              [-5.48416805267334, 56.437362670898551],
              [-5.400277137756348, 56.466526031494141],
              [-5.462500095367432, 56.493473052978572],
              [-5.363609790802002, 56.602085113525334],
              [-5.185276985168343, 56.699584960937614],
              [-5.302499771118107, 56.712081909179858],
              [-5.406390190124512, 56.64236068725603],
              [-5.493055820465031, 56.606803894042969],
              [-5.55916690826416, 56.544582366943587],
              [-5.678612232208252, 56.500137329101506],
              [-5.771944999694824, 56.532360076904524],
              [-5.928609848022461, 56.563472747802791],
              [-6.001389980316162, 56.619304656982422],
              [-5.980832099914437, 56.673194885253906],
              [-6.050834178924561, 56.692638397216911],
              [-6.186944007873535, 56.687084197998104],
              [-6.228055000305176, 56.716526031494084],
              [-6.18861198425293, 56.754859924316634],
              [-6.064723014831543, 56.760692596435604],
              [-5.959722995758057, 56.784305572509993],
              [-5.905276775360107, 56.750694274902401],
              [-5.860277175903263, 56.82986068725603],
              [-5.78583383560175, 56.837081909179744],
              [-5.746388912200928, 56.884304046630916],
              [-5.872499942779484, 56.880138397217081],
              [-5.88361120223999, 56.922637939453125],
              [-5.806942939758244, 57.014305114746207],
              [-5.798055171966553, 57.065971374511776],
              [-5.723054885864201, 57.118194580078352],
              [-5.625277996063232, 57.215694427490234],
              [-5.662500858306828, 57.280971527099609],
              [-5.726943016052246, 57.279304504394531],
              [-5.685834884643555, 57.341804504394645],
              [-5.821387767791748, 57.361804962158203],
              [-5.810277938842773, 57.401805877685831],
              [-5.853610992431641, 57.442085266113509],
              [-5.870833873748722, 57.501529693603629],
              [-5.837500095367375, 57.579303741455305],
              [-5.743054866790771, 57.544303894043196],
              [-5.65749979019165, 57.547916412353629],
              [-5.737500190734863, 57.609027862548828],
              [-5.819722175598088, 57.648193359375],
              [-5.760278224945068, 57.706806182861271],
              [-5.809165000915527, 57.746528625488395],
              [-5.808055877685547, 57.862361907959212],
              [-5.690277099609375, 57.869304656982536],
              [-5.665832996368408, 57.798473358154524],
              [-5.58250093460083, 57.837081909179915],
              [-5.639722824096623, 57.862915039062614],
              [-5.636944770812988, 57.913749694824446],
              [-5.551387786865234, 57.909305572509822],
              [-5.537498950958252, 57.867637634277401],
              [-5.453054904937744, 57.852085113525447],
              [-5.424167156219482, 57.908748626709098],
              [-5.214721202850342, 57.920139312744368],
              [-5.191390037536621, 57.954029083251953],
              [-5.309722900390625, 57.989860534668196],
              [-5.45861196517933, 58.068473815918026],
              [-5.287500858306885, 58.078193664550838],
              [-5.313611030578556, 58.171527862549055],
              [-5.405278205871525, 58.234306335449219],
              [-5.343054771423283, 58.251251220703239],
              [-5.154723167419434, 58.254306793213118],
              [-5.138055801391602, 58.310417175292969],
              [-5.187499046325627, 58.352916717529524],
              [-5.112500190734806, 58.398471832275391],
              [-5.082499980926457, 58.45124816894554],
              [-5.126945018768254, 58.488471984863338],
              [-5.051942825317326, 58.540973663330305],
              [-5.011943817138615, 58.593471527099666],
              [-4.868055820465088, 58.609027862548999],
              [-4.795278072357178, 58.577640533447322],
              [-4.738056182861271, 58.579029083252067],
              [-4.650834083557072, 58.533195495605469],
              [-4.543055057525635, 58.582084655761946],
              [-4.4352769851684, 58.555973052978459],
              [-4.409167766571045, 58.52430725097679],
              [-4.30194616317749, 58.544303894042969],
              [-4.215277194976807, 58.532917022705078],
              [-4.149166107177678, 58.566249847412223],
              [-4.082499027252197, 58.559860229492301],
              [-3.953056097030526, 58.5745849609375],
              [-3.920277118682804, 58.559860229492301],
              [-3.774166107177678, 58.575138092041243],
              [-3.649723052978459, 58.62402725219755],
              [-3.535279035568237, 58.622917175293196],
              [-3.363611936569214, 58.595695495605582],
              [-3.414167881011963, 58.639862060546875],
              [-3.200834035873356, 58.655971527099609],
              [-3.162501096725407, 58.63874816894554],
              [-3.024167060852051, 58.644306182861328],
              [-3.066943883895874, 58.563751220703125],
              [-3.121390104293766, 58.530693054199162],
              [-3.126944065093994, 58.48597335815424],
              [-3.050278902053833, 58.479862213134709],
              [-3.051388978958016, 58.446250915527344],
              [-3.106389045715332, 58.370971679687557],
              [-3.219722986221313, 58.305416107177962],
              [-3.386389970779419, 58.266803741455021],
              [-3.506387948989811, 58.172084808349609],
              [-3.743056058883667, 58.068195343017635],
              [-3.83250093460083, 58.039585113525447],
              [-3.844721078872681, 58.007637023925838],
              [-3.983612060546818, 57.969028472900504],
              [-4.018056869506836, 57.863750457763786],
              [-3.890832901000977, 57.821250915527571],
              [-3.779722929000798, 57.849029541015625],
              [-3.976946115493718, 57.692916870117244],
              [-4.073057174682617, 57.731803894043196],
              [-4.158054828643742, 57.688472747803019],
              [-4.088612079620304, 57.664306640625057],
              [-4.102500915527344, 57.605415344238565],
              [-4.23416614532465, 57.500694274902628],
              [-4.368609905242806, 57.512084960937727],
              [-4.375833034515381, 57.479862213134766],
              [-4.113612174987793, 57.515972137451399],
              [-4.010834217071533, 57.600692749023438],
              [-3.933056116104126, 57.585693359375227],
              [-3.79916596412653, 57.609306335449219],
              [-3.703610897064209, 57.657917022705135],
              [-3.550277948379517, 57.661529541015625],
              [-3.483611106872559, 57.703193664551009],
              [-3.346945047378483, 57.724861145019531],
              [-3.17305588722229, 57.687915802002067],
              [-3.017499923705998, 57.664306640625057],
              [-2.849168062210083, 57.70624923706049],
              [-2.553056001663208, 57.670696258544979],
              [-2.341389894485474, 57.671527862548771],
              [-2.3002769947052, 57.695972442626953],
              [-2.178611993789673, 57.674026489257869],
              [-2.119720935821533, 57.700973510742472],
              [-1.91527795791626, 57.673194885254077],
              [-1.82805597782135, 57.587638854980526],
              [-1.779165983200073, 57.460693359375],
              [-1.859166026115417, 57.388473510742415],
              [-1.971943974494934, 57.325138092041129],
              [-2.043056011199894, 57.243194580078239],
              [-2.075834035873413, 57.180416107177734],
              [-2.053056001663151, 57.120418548584041],
              [-2.0913889408111, 57.067916870117472],
              [-2.206943988799935, 56.963195800781364],
              [-2.19749903678894, 56.906806945800781],
              [-2.324166059493962, 56.794303894042969],
              [-2.401387929916382, 56.773471832275447],
              [-2.508610010147038, 56.649860382080305],
              [-2.480278015136719, 56.620971679687727],
              [-2.549166917800903, 56.560695648193303],
              [-2.606945991516113, 56.549861907959155],
              [-2.707499027252197, 56.499027252197379],
              [-2.786389112472534, 56.478473663330021],
              [-2.988611936569214, 56.450695037841797],
              [-3.080832958221379, 56.450973510742188],
              [-3.24194502830494, 56.353195190429744],
              [-2.93638801574707, 56.447082519531534],
              [-2.803056001663094, 56.435138702392692],
              [-2.81250095367426, 56.388748168945256],
              [-2.880278110504094, 56.362361907959155],
              [-2.778055906295776, 56.332916259765625],
              [-2.687499046325684, 56.326805114746094],
              [-2.584166049957162, 56.278194427490348],
              [-2.68861198425293, 56.221527099609375],
              [-2.844722986221313, 56.182918548584212],
              [-2.955276966094914, 56.210140228271484],
              [-3.084167003631535, 56.139026641845703],
              [-3.144721031188908, 56.119304656982706],
              [-3.17361211776722, 56.062084197998331],
              [-3.26583194732666, 56.059307098388672],
              [-3.387499094009399, 56.022640228271484],
              [-3.575834035873413, 56.058471679687727],
              [-3.578612089157048, 56.018749237060604],
              [-3.511389017105103, 55.999305725097827],
              [-3.265279054641667, 55.979305267334269],
              [-3.16749906539917, 55.985973358154581],
              [-3.06472110748291, 55.945137023925952],
              [-2.923054933547974, 55.972915649414347],
              [-2.86083197593689, 56.039585113525504],
              [-2.774166107177678, 56.067359924316349],
              [-2.624166965484619, 56.052360534668196],
              [-2.588609933853149, 56.015972137451172],
              [-2.398612022399902, 55.969860076904297],
              [-2.323610067367554, 55.932083129882926],
              [-2.203612089156991, 55.929027557373047],
              [-2.072499990463257, 55.869026184082202],
              [-1.989168047904968, 55.755416870117415],
              [-1.874722957611084, 55.685695648193587],
              [-1.829722046852055, 55.638748168945597],
              [-1.693055987358093, 55.606529235839787],
              [-1.628054976463318, 55.554584503173828],
              [-1.591943979263306, 55.490695953369141],
              [-1.57916700839985, 55.407081604003906],
              [-1.610277056694031, 55.38791656494152],
              [-1.550832986831665, 55.322360992431697],
              [-1.571944952011108, 55.275417327881087],
              [-1.50916600227356, 55.198749542236555],
              [-1.525277018547058, 55.159027099609489],
              [-1.496945023536625, 55.103195190429631],
              [-1.379166007041931, 54.98597335815424],
              [-1.360277056693917, 54.890140533447493],
              [-1.29694402217865, 54.765693664551009],
              [-1.198611021041813, 54.680137634277287],
              [-1.1686110496521, 54.637359619140739],
              [-0.970278024673405, 54.586807250976619],
              [-0.783056020736637, 54.559307098388615],
              [-0.661388993263245, 54.499027252197209],
              [-0.590278029441833, 54.487915039062671],
              [-0.520832002162877, 54.447360992431641],
              [-0.414723008871078, 54.330417633056584],
              [-0.395832985639572, 54.272640228271428],
              [-0.353612005710545, 54.240695953369368],
              [-0.266945987939778, 54.218193054199219],
              [-0.236945003271103, 54.162918090820312],
              [-0.098610997200012, 54.131248474121264],
              [-0.211389005184174, 54.060695648193644],
              [-0.212498992681503, 54.004859924316463],
              [-0.155833005905151, 53.902362823486442],
              [-0.024167999625149, 53.778472900390682],
              [0.118611000478325, 53.661804199218693],
              [-0.054168000817299, 53.6290283203125],
              [-0.106944002211094, 53.635692596435604],
              [-0.287499010562897, 53.742084503174055],
              [-0.29305499792099, 53.71374893188505],
              [-0.210277006030083, 53.642360687256087],
              [-0.094723001122475, 53.581527709960938],
              [-0.031943999230862, 53.566249847412337],
              [0.034166999161357, 53.510139465332315],
              [0.154165998101234, 53.476528167724609],
              [0.264165997505188, 53.344860076904411],
              [0.338611990213394, 53.238471984863509],
              [0.356388986110801, 53.1834716796875],
              [0.33749899268156, 53.094306945800838],
              [0.155834004282951, 53.009029388427734],
              [0.069168001413402, 52.923473358154524],
              [0.182500004768372, 52.860973358154581],
              [0.224722996354103, 52.823192596435661],
              [0.351943999528942, 52.804862976074219],
              [0.444721013307685, 52.850139617919865],
              [0.49583300948143, 52.955696105957145],
              [0.528612017631588, 52.975139617920149],
              [0.631945014, 52.974029541015625],
              [0.689720988273621, 52.988193511963175],
              [0.966943979263363, 52.964584350586165],
              [0.994722008705196, 52.978195190429801],
              [1.136945009231624, 52.949859619140625],
              [1.29916703701025, 52.934028625488281],
              [1.425832033157349, 52.885692596435774],
              [1.603611946106014, 52.79013824462902],
              [1.697500944137687, 52.722362518310547],
              [1.744166016578731, 52.630973815918196],
              [1.733610987663326, 52.551528930664176],
              [1.76249897480011, 52.47652816772478],
              [1.680276989936829, 52.321804046630803],
              [1.632500052452144, 52.275695800781364],
              [1.622498989105281, 52.20402908325218],
              [1.581943988800106, 52.087638854980753],
              [1.490277051925716, 52.057640075683821],
              [1.370278000831661, 51.965137481689453],
              [1.282500028610343, 51.968471527099837],
              [1.245833039283752, 51.889026641845646],
              [1.290277957916317, 51.863193511962947],
              [1.234166026115417, 51.81735992431652],
              [1.130831956863517, 51.776248931884879],
              [1.045833945274353, 51.769306182861328],
              [0.995278000831661, 51.814582824707202],
              [0.900833010673523, 51.791526794433594],
              [0.901944994926453, 51.745693206787337],
              [0.948055028915519, 51.734584808349609],
              [0.949723005294743, 51.682918548584212],
              [0.918610990047455, 51.585693359375171],
              [0.785833001136893, 51.521251678467081],
              [0.649722993373985, 51.539306640624943],
              [0.548054993152732, 51.487361907958984],
              [0.700832009315491, 51.472637176513786],
              [0.576945006847438, 51.390972137451286],
              [0.682498991489354, 51.380416870117301],
              [0.896389007568473, 51.341526031494368],
              [1.003054976463318, 51.348472595215071],
              [1.031944990158081, 51.365970611572379],
              [1.423611998558158, 51.392639160156193],
              [1.444167971611137, 51.350971221923828],
              [1.376945018768311, 51.288749694824219],
              [1.404723048210144, 51.232360839843864],
              [1.40527701377863, 51.170970916748047],
              [1.380833029747066, 51.142360687255859],
              [1.191388964653129, 51.076526641845874],
              [1.045833945274353, 51.053749084472656],
              [0.978055000305289, 51.005416870117472],
              [0.973610997200012, 50.910415649414176],
              [0.792499005794639, 50.933193206787223],
              [0.667500019073486, 50.871250152588004],
              [0.41694501042366, 50.830696105957315],
              [0.357499003410396, 50.813472747802678],
              [0.241944000124988, 50.733749389648665],
              [0.066389001905918, 50.78208160400402],
              [0.035277001559791, 50.780418395996264],
              [-0.157499000429993, 50.822360992431697],
              [-0.282501012086868, 50.82624816894554],
              [-0.36472299695015, 50.809028625488452],
              [-0.540278017520848, 50.801807403564737],
              [-0.730278015136719, 50.770694732666129],
              [-0.785834014415741, 50.722084045410384],
              [-0.916944026947021, 50.779861450195312],
              [-0.948610007762909, 50.842361450195256],
              [-1.044167041778564, 50.82902908325201],
              [-1.032500982284489, 50.786251068115234],
              [-1.136944055557194, 50.773471832275391],
              [-1.304167032241764, 50.83819580078125],
              [-1.342499017715454, 50.78541564941429],
              [-1.527498960494995, 50.747081756591911],
              [-1.585278034210205, 50.719306945801065],
              [-1.690832972526493, 50.737640380859489],
              [-1.744721055030709, 50.713748931884936],
              [-1.83027803897852, 50.720695495605469],
              [-1.932499051094055, 50.698471069335994],
              [-1.959167003631592, 50.590694427490178],
              [-2.106944084167424, 50.596527099609375],
              [-2.205833911895752, 50.622081756591854],
              [-2.243609905242863, 50.615695953369141],
              [-2.423612117767277, 50.635692596435661],
              [-2.465832948684579, 50.592082977294922],
              [-2.506387948989754, 50.598194122314453],
              [-2.604721069335938, 50.650138854980639],
              [-2.79249906539917, 50.717918395996321],
              [-2.910278081893864, 50.733749389648665],
              [-3.018611907958984, 50.698192596435604],
              [-3.263056039810181, 50.671249389648722],
              [-3.304721117019596, 50.628749847412337],
              [-3.408611059188843, 50.611251831054688],
              [-3.466943025588989, 50.577083587646541],
              [-3.514723062515259, 50.482360839843977],
              [-3.559721946716309, 50.437084197998104],
              [-3.513611078262215, 50.399028778076172],
              [-3.516388893127441, 50.347362518310547],
              [-3.608056068420353, 50.319583892822322],
              [-3.643610954284611, 50.289306640625227],
              [-3.658056020736694, 50.217639923095703],
              [-3.719166040420532, 50.202083587646541],
              [-3.77361011505127, 50.225692749023722],
              [-3.824166059494019, 50.217361450195483],
              [-3.879165887832642, 50.284305572509822],
              [-3.993053913116398, 50.306804656982479],
              [-4.03916597366333, 50.29264068603544],
              [-4.163054943084717, 50.360694885253963],
              [-4.208611011505013, 50.317916870117415],
              [-4.291944980621338, 50.358470916748274],
              [-4.436943054199219, 50.360416412353629],
              [-4.53694486618042, 50.323471069336165],
              [-4.765833854675293, 50.323192596435774],
              [-4.798057079315186, 50.230415344238338],
              [-4.863055229186955, 50.234306335449162],
              [-4.958610057830811, 50.201248168945426],
              [-5.008612155914307, 50.139026641845646],
              [-5.088612079620361, 50.124305725097656],
              [-5.059165954589787, 50.051528930664176],
              [-5.100276947021428, 50.004028320312614],
              [-5.156389236450138, 50.007362365722827],
              [-5.197638988494759, 49.95847320556669],
              [-5.245139122009277, 49.974304199219034],
              [-5.279166221618539, 50.056251525879134],
              [-5.333610057830754, 50.090415954589901],
              [-5.428610801696777, 50.104583740234489],
              [-5.491387844085637, 50.128471374511719],
              [-5.572500228881836, 50.052639007568359],
              [-5.670833110809269, 50.03458404541027],
              [-5.704721927642822, 50.135417938232422],
              [-5.671946048736515, 50.166805267334041],
              [-5.499722957611084, 50.219860076904411],
              [-5.436944007873478, 50.193748474121321],
              [-5.245833873748779, 50.28680419921875],
              [-5.158610820770207, 50.348751068115462],
              [-5.146944046020451, 50.405693054199276],
              [-5.042500972747746, 50.443473815918026],
              [-5.024721145629883, 50.539859771728743],
              [-4.93750095367426, 50.55347061157255],
              [-4.870277881622314, 50.595138549804972],
              [-4.795832157134896, 50.598472595214844],
              [-4.757499217987004, 50.672084808349723],
              [-4.655278205871582, 50.715972900390625],
              [-4.652500152587834, 50.739582061767635],
              [-4.557500839233398, 50.787639617919979],
              [-4.569721221923771, 50.904304504394645],
              [-4.522500038146973, 51.021526336670206],
              [-4.425833225250244, 51.014305114746321],
              [-4.389165878295842, 50.994304656982422],
              [-4.309165954589844, 50.995140075683537],
              [-4.215277194976807, 51.076805114746094],
              [-4.220833778381348, 51.184581756591911],
              [-4.121943950653076, 51.212360382080078],
              [-3.770277976989746, 51.237915039062784],
              [-3.616389036178589, 51.217918395996037],
              [-3.535279035568237, 51.231529235839844],
              [-3.446389913558903, 51.209026336670206],
              [-3.409723043441772, 51.183471679687727],
              [-3.274723052978459, 51.180416107178019],
              [-3.157500982284432, 51.208473205566634],
              [-3.057498931884709, 51.203193664550895],
              [-2.999722957611027, 51.232360839843864],
              [-3.016943931579533, 51.260417938232422],
              [-2.961944103240853, 51.382083892822436],
              [-2.907500028610173, 51.400138854980469],
              [-2.808053970336857, 51.482082366943587],
              [-2.724165916442814, 51.498474121093864],
              [-2.630276918411255, 51.605693817138899],
              [-2.681945085525513, 51.604862213134936],
              [-2.843055009841862, 51.545696258544865],
              [-2.998610973358154, 51.533195495605582],
              [-3.119721889495793, 51.490417480468807],
              [-3.169167041778508, 51.405693054199446],
              [-3.290832042694035, 51.385692596435547],
              [-3.40416693687439, 51.380695343017692],
              [-3.560277938842773, 51.401248931884822],
              [-3.660834074020386, 51.479026794433707],
              [-3.718610048294067, 51.477916717529354],
              [-3.762499094009399, 51.539306640624943],
              [-3.844167947769108, 51.619304656982536],
              [-3.965831995010319, 51.61180496215826],
              [-3.990278005599919, 51.563194274902514],
              [-4.114721775054818, 51.571250915527571],
              [-4.234723091125431, 51.540973663330249],
              [-4.280834197998047, 51.560695648193416],
              [-4.306387901306096, 51.609027862548771],
              [-4.248611927032471, 51.644027709960881],
              [-4.200833797454834, 51.626251220703409],
              [-4.138054847717285, 51.642639160156364],
              [-4.216945171356144, 51.685138702392578],
              [-4.31138801574707, 51.67319488525402],
              [-4.376389026641846, 51.724029541015852],
              [-4.559166908264103, 51.741806030273494],
              [-4.671389102935791, 51.728473663330078],
              [-4.715832233428898, 51.655139923095817],
              [-4.788055896758976, 51.637084960937557],
              [-4.847499847412109, 51.645973205566577],
              [-4.930277824401799, 51.598194122314624],
              [-5.057499885559082, 51.619583129883097],
              [-5.065834045410099, 51.662918090820312],
              [-5.111388206481877, 51.692359924316634],
              [-5.228610038757267, 51.722637176513729],
              [-5.104166984557992, 51.812637329101506],
              [-5.126389026641789, 51.855972290039233],
              [-5.218056201934758, 51.872638702392578],
              [-5.304721832275334, 51.863750457763899],
              [-5.307499885559082, 51.907917022705078],
              [-5.194166183471623, 51.950416564941634],
              [-5.084166049957275, 51.970417022705192],
              [-5.070833206176701, 52.030418395996378],
              [-4.885832786560059, 52.020973205566406],
              [-4.789721965789738, 52.059307098388786],
              [-4.730277061462402, 52.118194580078068],
              [-4.639166831970215, 52.138748168945426],
              [-4.517499923706055, 52.135971069336108],
              [-4.377499103546086, 52.215694427490348],
              [-4.326942920684758, 52.212917327880859],
              [-4.209722995758057, 52.263748168945312],
              [-4.13861083984375, 52.325695037842024],
              [-4.052498817443791, 52.480693817138672],
              [-4.059720993041992, 52.526805877685831],
              [-4.130277156829834, 52.611251831054688],
              [-4.061389923095703, 52.722084045410156],
              [-4.145277976989746, 52.801528930664119],
              [-4.118611812591553, 52.850139617919865],
              [-4.168612003326416, 52.906528472900447],
              [-4.220833778381348, 52.919029235839957],
              [-4.393610000610352, 52.894584655761946],
              [-4.465278148651066, 52.867637634277344],
              [-4.51472091674799, 52.792640686035156],
              [-4.586945056915283, 52.822082519531477],
              [-4.641388893127385, 52.800140380859602],
              [-4.719723224639893, 52.802917480468864],
              [-4.726388931274357, 52.853195190429915],
              [-4.576387882232609, 52.939304351806868],
              [-4.530279159545898, 52.937362670898722],
              [-4.356389999389648, 53.031806945800724],
              [-4.310277938842717, 53.129581451416016],
              [-4.121943950653076, 53.237640380859375],
              [-4.083055019378662, 53.229583740234489],
              [-3.881387948989811, 53.289859771728743],
              [-3.850833892822266, 53.323471069335938],
              [-3.779166936874333, 53.328750610351847],
              [-3.705832958221436, 53.293472290039347],
              [-3.609721899032536, 53.290138244629134],
              [-3.486388921737614, 53.32652664184576],
              [-3.323055028915292, 53.356529235840071],
              [-3.303611040115356, 53.334861755371378],
              [-3.079166889190617, 53.236251831054972],
              [-3.12860989570612, 53.328193664550895],
              [-3.200278043746891, 53.385692596435831],
              [-3.038611888885441, 53.441249847412109],
              [-2.998054027557316, 53.370418548584098],
              [-2.92638897895813, 53.307083129882983],
              [-2.880278110504094, 53.334304809570426],
              [-2.987499952316284, 53.387916564941349],
              [-3.100277900695801, 53.566528320312727],
              [-3.051388978958016, 53.622360229492358],
              [-2.945276975631657, 53.709583282470646],
              [-3.017499923705998, 53.7398681640625],
              [-3.058054924011174, 53.778472900390682],
              [-3.046387910842896, 53.923194885254134],
              [-2.914721965789738, 53.946529388427734],
              [-2.874166965484562, 53.971527099609659],
              [-2.905277967452889, 54.02208328247093],
              [-2.893054962158203, 54.059860229492131],
              [-2.818054914474487, 54.08902740478544],
              [-2.836945056915283, 54.17402648925804],
              [-2.919723033905029, 54.15930557251005],
              [-2.996387958526554, 54.175971984863395],
              [-3.060832977294865, 54.161529541015568],
              [-3.157500982284432, 54.08902740478544],
              [-3.2286119461059, 54.094581604003849],
              [-3.240278959274292, 54.202915191650618],
              [-3.324167013168335, 54.190692901611555],
              [-3.40416693687439, 54.263748168945426],
              [-3.423055887222233, 54.340415954590128],
              [-3.639722108840942, 54.511806488037053],
              [-3.584166049957219, 54.564025878906421],
              [-3.575834035873413, 54.644027709960994],
              [-3.507500886917057, 54.715694427490348],
              [-3.433610916137695, 54.764305114746094],
              [-3.434722900390625, 54.806251525879077],
              [-3.365278005599976, 54.892917633056641],
              [-3.295279026031494, 54.882915496826229],
              [-3.280277967453003, 54.936248779296989],
              [-3.206387996673527, 54.954582214355753],
              [-3.135833024978581, 54.92791748046875],
              [-3.07027792930603, 54.938472747802848],
              [-3.091389894485474, 54.975418090820312],
              [-3.278054952621403, 54.965694427490291],
              [-3.373054027557316, 54.970138549804915],
              [-3.437499046325627, 54.992362976074162],
              [-3.476943969726562, 54.967361450195597],
              [-3.556387901306152, 54.978473663330135],
              [-3.596389055252075, 54.882915496826229],
              [-3.705832958221436, 54.883193969726619],
              [-3.825834035873413, 54.822639465332031],
              [-3.964723110198975, 54.770694732666016],
              [-4.159722805023193, 54.779026031494084],
              [-4.220276832580566, 54.8245849609375],
              [-4.419722080230713, 54.884860992431925],
              [-4.410277843475285, 54.827915191650391],
              [-4.352499008178711, 54.81291580200201],
              [-4.365831851959229, 54.731529235840071],
              [-4.407498836517334, 54.677639007568587],
              [-4.521944046020508, 54.713470458984489],
              [-4.58583307266224, 54.765693664551009],
              [-4.714722156524545, 54.825695037841854],
              [-4.788055896758976, 54.835693359375227],
              [-4.850833892822266, 54.865970611572379],
              [-4.938055038452148, 54.830696105957031],
              [-4.948612213134709, 54.796249389648551],
              [-4.881389141082707, 54.670696258544865],
              [-4.993610858917236, 54.734859466552962],
              [-5.001389980316105, 54.772640228271712],
              [-5.138055801391602, 54.850139617920036],
              [-5.185276985168343, 54.911804199218807],
              [-5.187499046325627, 54.976528167724837],
              [-5.10916805267334, 55.01847076416044],
              [-5.074722766876164, 54.995971679687784],
              [-5.064166069030762, 54.927639007568359],
              [-4.99138879776001, 54.917083740234375],
              [-5.056389808654785, 55.012638092041129],
              [-5.053610801696721, 55.050140380859659],
              [-4.993610858917236, 55.143749237060547],
              [-4.860833168029728, 55.226806640625],
              [-4.84638786315918, 55.324584960937614],
              [-4.775833129882756, 55.359306335449332],
              [-4.771388053894043, 55.400695800781364],
              [-4.656943798065186, 55.439861297607479],
              [-4.623054981231633, 55.508750915527344],
              [-4.694723129272404, 55.605972290039006],
              [-4.803054809570312, 55.640140533447322],
              [-4.905831813812199, 55.699306488037394],
              [-4.885276794433594, 55.729862213134766],
            ],
          ],
          [
            [
              [-3.343610048294067, 58.933750152587947],
              [-3.213610887527466, 58.878471374511719],
              [-3.210832118988037, 58.800693511962891],
              [-3.291944026946965, 58.775695800781477],
              [-3.433610916137695, 58.870971679687443],
              [-3.396388053893986, 58.924026489257983],
              [-3.343610048294067, 58.933750152587947],
            ],
          ],
          [
            [
              [-2.801944017410221, 59.087638854980412],
              [-2.835278987884521, 59.024581909179915],
              [-2.899723052978516, 59.035137176513842],
              [-2.801944017410221, 59.087638854980412],
            ],
          ],
          [
            [
              [-3.196945905685425, 59.15486145019554],
              [-3.090276956558228, 59.118473052978516],
              [-3.000833034515381, 59.063472747802848],
              [-3.036390066146851, 59.010417938232479],
              [-2.918055057525635, 58.983470916748047],
              [-2.7063889503479, 58.97319412231468],
              [-2.710277080535889, 58.924304962158203],
              [-2.779721975326538, 58.916248321533317],
              [-2.986943960189819, 58.747917175293253],
              [-3.022500038146916, 58.807640075683537],
              [-2.9252769947052, 58.878192901611555],
              [-2.973611116409302, 58.939861297607536],
              [-3.134722948074341, 58.915973663330305],
              [-3.222498893737793, 58.925140380859318],
              [-3.239722967147827, 58.985416412353743],
              [-3.32583308219904, 58.952362060546989],
              [-3.368056058883667, 58.997081756591911],
              [-3.348053932189941, 59.099582672119141],
              [-3.310833930969125, 59.139305114746378],
              [-3.196945905685425, 59.15486145019554],
            ],
          ],
          [
            [
              [-3.076944112777653, 59.200416564941406],
              [-2.976388931274357, 59.159584045410327],
              [-3.042500019073486, 59.126529693603743],
              [-3.115277051925545, 59.161804199219034],
              [-3.076944112777653, 59.200416564941406],
            ],
          ],
          [
            [
              [-2.959167957305851, 59.360694885253963],
              [-2.958611965179443, 59.325973510742415],
              [-2.881943941116333, 59.286529541015739],
              [-2.995277881622314, 59.264583587646655],
              [-3.021944046020508, 59.30152893066429],
              [-2.959167957305851, 59.360694885253963],
            ],
          ],
        ],
      },
    },
  ],
}

export default map
