import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { Chart as ReactChart } from "react-chartjs-2"
import ukRegions from "./UkRegionsGeo"
import britishIsles from "./BritishIslesGeo"

const Container = styled.div``

interface Props {
  regionSpend: { [key: string]: number }
}

const RegionHeatmap: FC<Props> = ({ regionSpend }) => {
  const totalSpend = Object.values(regionSpend).reduce((prev, curr) => prev + curr, 0)

  const getValue = (regionName: string) => {
    let spend = regionSpend[regionName]
    if (isNaN(spend)) {
      spend = 0
      return 0
    }
    console.log("TOTAL SPEND IS", totalSpend)
    return (spend / totalSpend) * 100
  }

  const data = ukRegions.features.map(feature => ({
    feature,
    value: getValue(feature.properties.name),
  }))

  const labels = ukRegions.features.map(feature => feature.properties.name)

  return (
    <Container>
      <ReactChart
        type={"choropleth"}
        options={{
          elements: {
            geoFeature: {
              // borderWidth: 0,
              borderColor: "white",
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              color: "white",
              formatter: ({ value }) => `${value.toFixed(0)}%`,
            },
          },
          scales: {
            xy: {
              projection: "equalEarth",
            },
            color: {
              interpolate: (value: number) => {
                if (value === 0) {
                  return "#e5e5e5"
                }
                if (value < 0.1) {
                  value += 0.2
                } else if (value < 0.35) {
                  value += 0.15
                } else if (value < 0.5) {
                  value += 0.05
                }

                return `rgba(47, 174, 175, ${value})`
              },
              legend: {
                width: 0,
                // display: false,
                // position: "bottom-right",
                // align: "bottom",
              },
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: "TEST",
              outline: [britishIsles.features[0]],
              showOutline: false,
              data: data,
            },
          ],
        }}
      />
    </Container>
  )
}

export default RegionHeatmap
