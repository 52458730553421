import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { DonutDataType } from "./Donut"

const Container = styled.div`
  margin-left: 10px;
`
const ItemContainer = styled.div`
  display: flex;
  font-size: 10px;
  margin-bottom: 5px;
  align-items: center;
`
const Color = styled.div<{ color: string }>`
  width: 15px !important;
  height: 15px !important;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`
const Label = styled.div`
  max-width: 120px;
`

interface Props {
  data: DonutDataType
}
const DonutLegend: FC<Props> = ({ data }) => {
  const colors = data.datasets[0].backgroundColor
  return (
    <Container>
      {data.labels.map((label, i) => {
        let colorI = i
        if (i > colors.length - 1) {
          colorI = i - colors.length
        }
        return (
          <ItemContainer key={`${label}-${i}`}>
            <Color color={colors[colorI]} />
            <Label>{label}</Label>
          </ItemContainer>
        )
      })}
    </Container>
  )
}

export default DonutLegend
