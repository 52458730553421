import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { Chart, ArcElement, Tooltip, Legend, CoreChartOptions } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { ChoroplethController } from "chartjs-chart-geo"
import DonutLegend from "./DonutLegend"

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels, ChoroplethController)

const DoughnutContainer = styled.div`
  padding: 10px;
  display: flex;
  margin-top: 20px;
  position: relative;
`
const DoughnutInnerContainer = styled.div`
  position: relative;
  flex: 1;
`
const NoData = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
`

export interface DonutDataType {
  labels: string[]
  datasets: DatasetType[]
}
export interface DatasetType {
  id: number
  label: string
  data: number[]
  backgroundColor: string[]
}

const p: "right" = "right"
const start: "start" = "start"
const white: "white" = "white"
const size: "10px" = "10px"
const zero: 0 = 0

const options = {
  responsive: true,
  elements: {
    arc: {
      borderWidth: zero,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: p,
      align: start,
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        font: {
          size: 10,
        },
      },
    },
    datalabels: {
      color: white,
      font: {
        size: 10,
      },
      formatter: (value: any) => `${value.toFixed(0)}%`,
      labels: {
        title: {},
        value: {},
      },
    },
  },
}

interface Props {
  data: { [key: string]: number }
}

const Donut: FC<Props> = ({ data }) => {
  const hasData = Object.keys(data).length > 0
  return (
    <DoughnutContainer>
      <>
        <DoughnutInnerContainer>
          <Doughnut data={getDonutData(data)} plugins={[ChartDataLabels]} options={options} />
        </DoughnutInnerContainer>
        <DonutLegend data={getDonutData(data)} />
      </>

      {!hasData && <NoData>No data available</NoData>}
    </DoughnutContainer>
  )
}

const getDonutData = (baseData: { [key: string]: number }): DonutDataType => {
  const total = Object.values(baseData).reduce((prev, curr) => prev + curr, 0)

  // const data = Object.keys(baseData)
  //   .map(key => {
  //     return (baseData[key] / total) * 100
  //   })
  //   .sort()
  //   .reverse()
  //
  // const labels: string[] = data.map(number => {
  //   return Object.keys(baseData).find(key => (baseData[key] / total) * 100 == number) || ""
  // })

  const objectArray = Object.keys(baseData).map(key => ({ label: key, value: baseData[key] }))

  objectArray.sort((a, b) => {
    if (a.value < b.value) {
      return 1
    }
    if (a.value > b.value) {
      return -1
    }
    return 0
  })

  const data = objectArray.map(object => object.value).map(value => (value / total) * 100)
  const labels = objectArray.map(object => object.label)

  // const values = Object.values(baseData).sort().reverse()
  //
  // const labels = values.map(
  //   value =>
  //     Object.keys(baseData).find(key => {
  //       return baseData[key] === value
  //     }) || ""
  // )
  //
  // const data = values.map(value => (value / total) * 100)

  let colors = ["#35a8ad", "#54b5ba", "#7cc6c9", "#91d1d1", "#afdddd"]
  if (data.length === 2) {
    colors = ["#35a8ad", "#91d1d1"]
  }
  if (data.length === 3) {
    colors = ["#35a8ad", "#7cc6c9", "#afdddd"]
  }
  return {
    labels,
    datasets: [
      {
        id: 1,
        label: "main",
        data,
        backgroundColor: colors,
        // backgroundColor: ["#35a8ad", "#91d1d1"],
      },
    ],
  }
}

export default Donut
